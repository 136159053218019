import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "../../App.styles";
import { surveyUrl } from "../../utils";
import { Container } from "./ErrorMessage.styles";

interface ErrorMessageProps {
  title: string;
  description: string;
  testId?: string;
  hideSurveyLink?: boolean;
}

export const ErrorMessage: FC<PropsWithChildren<ErrorMessageProps>> = ({
  title,
  description,
  testId,
  hideSurveyLink,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Container data-testid={testId}>
      {children}
      <h2>{title}</h2>
      <p>{description}</p>

      {!hideSurveyLink && (
        <Link href={surveyUrl} target="_blank">
          {t("survey_link_display")}
        </Link>
      )}
    </Container>
  );
};

export default ErrorMessage;
