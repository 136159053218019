import { PropsWithChildren, createContext, useState } from "react";

export const SessionTokenContext = createContext<{
  sessionTokenWrapper: [string, (token: string) => void];
  tokenExpirationWrapper: [number | undefined, (expiry: number) => void];
}>({
  sessionTokenWrapper: ["", (token: string) => {}],
  tokenExpirationWrapper: [0, (expiration: number) => {}],
});

export const SessionTokenProvider = ({ children }: PropsWithChildren<{}>) => {
  const [sessionToken, setSessionToken] = useState("");
  const [tokenExpiration, setTokenExpiration] = useState<number | undefined>();

  return (
    <SessionTokenContext.Provider
      value={{
        sessionTokenWrapper: [sessionToken, setSessionToken],
        tokenExpirationWrapper: [tokenExpiration, setTokenExpiration],
      }}
    >
      {children}
    </SessionTokenContext.Provider>
  );
};
