import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  MetricBaseData,
  getAbsTimeDifferenceInSeconds,
  getMetricsBaseData,
  metricEvents,
} from "../utils";
import { useHeap } from "./useHeap";

enum SwitchState {
  On = "on",
  Off = "off",
}
type DeviceState = SwitchState.On | SwitchState.Off;
type PermissionStatus = {
  microphone: PermissionState | null;
  camera: PermissionState | null;
};
type CallMetricsProps = {
  dateAndTimeDing?: Date;
  dateAndTimeConnected?: Date;
  videoInitState?: DeviceState;
  micInitState?: DeviceState;
  videoSwitched?: boolean;
  microphoneSwitched?: boolean;
  cameraViewSwitched?: boolean;
};
type ConnectedCallProps = {
  videoEnabled: boolean;
  micEnabled: boolean;
};

const handleState = (status?: boolean) => {
  return status ? SwitchState.On : SwitchState.Off;
};

const useCallMetrics = () => {
  const [callMetrics, setCallMetrics] = useState<CallMetricsProps>({});
  const [dateAndTimeInit, setDateAndTimeInit] = useState<Date>();
  const location = useLocation();
  const { track } = useHeap();
  const [state] = useState<MetricBaseData>(location.state);

  const trackMetric = useCallback(
    (metric: string, other?: any) => {
      return track(metric, {
        ...getMetricsBaseData(state),
        ...other,
      });
    },
    [track, state]
  );

  const errorCall = useCallback(() => setCallMetrics({}), []);

  const startCall = useCallback(
    ({ microphone, camera }: PermissionStatus) => {
      trackMetric(metricEvents.callPermissions, {
        microphone,
        camera,
      });
      setDateAndTimeInit(new Date());
    },
    [trackMetric]
  );

  const calling = useCallback(() => {
    setCallMetrics({
      ...callMetrics,
      dateAndTimeDing: new Date(),
    });
  }, [callMetrics]);

  const connectedCall = useCallback(
    ({ videoEnabled, micEnabled }: ConnectedCallProps) => {
      const states = {
        mic: handleState(micEnabled),
        cam: handleState(videoEnabled),
      };
      trackMetric(metricEvents.callConnected, {
        microphone: states.mic,
        camera: states.cam,
      });
      setCallMetrics({
        ...callMetrics,
        dateAndTimeConnected: new Date(),
        videoInitState: states.cam,
        micInitState: states.mic,
      });
    },
    [callMetrics, trackMetric]
  );

  const switchCameraView = useCallback(() => {
    setCallMetrics({ ...callMetrics, cameraViewSwitched: true });
  }, [callMetrics]);

  const switchCameraOnOff = useCallback(() => {
    callMetrics.videoInitState !== undefined &&
      setCallMetrics({ ...callMetrics, videoSwitched: true });
  }, [callMetrics]);

  const switchMicOnOff = useCallback(() => {
    callMetrics.micInitState !== undefined &&
      setCallMetrics({ ...callMetrics, microphoneSwitched: true });
  }, [callMetrics]);

  const finishCall = useCallback(() => {
    // Track Call Finished with duration & latency
    trackMetric(metricEvents.callFinished, {
      ...(callMetrics.dateAndTimeConnected && {
        duration: getAbsTimeDifferenceInSeconds(
          callMetrics.dateAndTimeConnected,
          new Date()
        ),
      }),
      ...(callMetrics.dateAndTimeDing &&
        dateAndTimeInit && {
          dingEventLatency: getAbsTimeDifferenceInSeconds(
            dateAndTimeInit,
            callMetrics.dateAndTimeDing
          ),
        }),
      ...(callMetrics.dateAndTimeConnected &&
        dateAndTimeInit && {
          callAnsweredLatency: getAbsTimeDifferenceInSeconds(
            dateAndTimeInit,
            callMetrics.dateAndTimeConnected
          ),
        }),
    });
    // Track if Camera View was switched during Call
    if (callMetrics.cameraViewSwitched) {
      trackMetric(metricEvents.guestSwitchedCameraView);
    }
    // If Video was switched between On/Off it won't trigger alwaysOn/Off events
    if (callMetrics.videoSwitched) {
      trackMetric(metricEvents.guestSwitchedCamera);
    } else {
      trackMetric(
        callMetrics.videoInitState === SwitchState.On
          ? metricEvents.guestCameraOn
          : metricEvents.guestCameraOff
      );
    }
    // If Mic was switched between On/Off it won't trigger alwaysOn/Off events
    if (callMetrics.microphoneSwitched) {
      trackMetric(metricEvents.guestSwitchedMicrophone);
    } else {
      trackMetric(
        callMetrics.micInitState === SwitchState.On
          ? metricEvents.guestMicrophoneOn
          : metricEvents.guestMicrophoneOff
      );
    }

    setCallMetrics({});
  }, [callMetrics, trackMetric, dateAndTimeInit]);

  const stopCall = useCallback(
    (fromResident?: boolean) => {
      fromResident
        ? trackMetric(metricEvents.residentEndedCall)
        : trackMetric(metricEvents.guestEndedCall);

      // Track Call events if Call was connected
      if (callMetrics.dateAndTimeConnected) {
        finishCall();
      }
    },
    [finishCall, trackMetric, callMetrics]
  );

  const trackOngoingCallError = useCallback(() => {
    trackMetric(metricEvents.ongoingCallError);
  }, [trackMetric]);

  const trackResidentRateLimitError = useCallback(() => {
    trackMetric(metricEvents.residentRateLimitError);
  }, [trackMetric]);

  const trackCallDroppedError = useCallback(() => {
    trackMetric(metricEvents.callDropped);
  }, [trackMetric]);

  return {
    startCall,
    calling,
    stopCall,
    finishCall,
    connectedCall,
    switchCameraOnOff,
    switchMicOnOff,
    switchCameraView,
    errorCall,
    trackMetric,
    trackOngoingCallError,
    trackResidentRateLimitError,
    trackCallDroppedError,
  };
};
export default useCallMetrics;
